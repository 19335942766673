.hocc-button {
	width: 100%;
	margin-bottom: var(--margin);
	button {
		transition: var(--transition);
		outline: none;
		cursor: pointer;
		padding: 0;
		border: none;
		background-color: transparent;
		border-bottom: 1px solid var(--color-fine);
		color: var(--color-fine);
		&:hover {
			color: var(--color);
			border-bottom: 1px solid var(--color);
		}
	}
}

.content {
	height: 100%;
}

.occ-deletion {
	max-width: 300px;
	.occ-content {
		text-align: center;
	}
	.occ-footer {
		padding-top: var(--padding);
		display: flex;
		justify-content: center;
	}
}

.renter-consumption {
	.renter-consumption-title {
		color: var(--color-header);
		margin-bottom: var(--margin-big);
		font-size: var(--text-big);
		display: flex;
		justify-content: center;
	}

	.consumption-total {
		display: flex;
		margin-bottom: var(--margin-big);
		border-bottom: 1px solid var(--color-fine);
		padding-bottom: var(--padding);
		align-items: center;

		.consumption-total-label {
			flex-grow: 1;
		}

		.renter-value {
		}
	}
	.renter-consumption-content {
		.renter-consumption-item {
			display: flex;
			margin-bottom: var(--margin-big);

			&:last-child {
				margin-bottom: 0;
			}

			.renter-name {
				flex-grow: 1;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-right: var(--margin);
			}

			.renter-value {
			}
		}
	}

	.renter-consumption-footer {
		margin-top: var(--margin);
		display: flex;
		justify-content: center;
	}
}

.hoccs-wrapper {
	height: 100%;

	.hoccs-table {
		height: calc(100% - 47px);
	}

	.hoccs-creation-footer {
		display: flex;
		justify-content: flex-end;
	}

	.hoccs-form {
		display: flex;
		justify-content: center;
	}
}

.home {
	display: grid;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	grid-template-rows: 300px calc(100% - 300px);
	grid-template-columns: calc(100% - 40%) 40%;
	grid-template-areas:
		"headL headR"
		"footer footer";

	@media (max-width: 900px) {
		grid-template-areas:
			"headL headL"
			"footer footer";
	}

	@media (max-height: 800px) {
		grid-template-rows: min-content fit-content(500px);
	}

	.home-metadata {
		grid-area: headL;
		position: relative;
		display: flex;
		justify-content: center;
		padding: 0 40px 40px 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		@media (max-width: 700px) {
			padding: 0 0 40px 0;
		}
		@media (max-width: 550px) {
			flex-direction: column;
			align-items: center;
		}

		.home-metadata-general {
			display: flex;
			align-items: center;
			margin-left: calc(3 * var(--margin-big));

			@media (max-width: 700px) {
				margin-left: var(--margin-big);
			}

			@media (max-width: 550px) {
				margin-left: 0px;

				margin-top: calc(3 * var(--margin-big));
			}

			.home-infos {
				display: flex;
				margin-bottom: var(--margin-big);

				&:last-child {
					margin: 0;
				}

				.home-icon {
					margin-right: var(--margin);
					i {
						font-size: 60px;
					}
				}

				.home-data {
					display: flex;
					flex-direction: column;
					justify-content: center;

					span {
						color: var(--color);
					}

					p {
						white-space: nowrap;
						font-size: var(--text-normal);
						color: var(--color-fine);
					}
				}
			}
		}

		.home-metadata-house {
			display: flex;
			flex-direction: column;
			justify-content: center;
			.home-infos {
				display: flex;
				margin-bottom: calc(3 * var(--margin-big));
				&:last-child {
					margin: 0;
				}

				.home-icon {
					margin-right: var(--margin);
					i {
						font-size: 60px;
					}
				}

				.home-data {
					display: flex;
					flex-direction: column;
					justify-content: center;

					p {
						white-space: nowrap;
						font-size: var(--text-normal);
						color: var(--color-fine);
					}
					#house-name {
						font-size: var(--text-big);
						color: var(--color);
					}
				}
			}
		}
	}

	.home-graphs {
		grid-area: headR;

		.img-wrapper {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-height: 90%;
				max-width: 100%;
			}
		}

		@media (max-width: 900px) {
			display: none;
		}
	}

	.home-occs {
		grid-area: footer;

		.home-occs-wrapper {
			height: 100%;
		}
	}
}
