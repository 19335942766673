:root {
	/* =======================================
    
            TEXT
    
        ======================================= */
	//Color
	--color: black;
	--color-header: #2e9afe;
	--color-header-fine: #2e9afecc;
	--color-header-raw: 0, 208, 214;
	--color-secondary: black;
	--color-fine: rgba(0, 0, 0, 0.6);

	//Size
	--text-bigger: 1.8em;
	--text-big: 1.5em;
	--text-normal: 1em;

	/* =======================================
    
            COLORS
    
        ======================================= */
	--success: #55ff20;
	--warning: rgba(255, 251, 33, 1);
	--error: #ff3131;
	--disabled: rgba(100, 100, 100, 0.6);

	--background-color: #d9f6fb;
	--background-color-item: #bef2fb;
	--background-color-hover: rgba(100, 100, 100, 0.15);
	--background-color-selected: rgba(100, 100, 100, 0.3);
	--background-color-disabled: rgba(0, 0, 0, 0.6);
	--background-color-highlighted: rgba(100, 100, 100, 0.3);
	--background-color-item-popup: #fefefe;

	/* =======================================
    
            STYLE
    
        ======================================= */
	--border-radius: 5px;
	--border-radius-small: 2px;
	--border-width: 1px;
	--border-color-strong: rgba(0, 0, 0, 0.6);
	--border: 1px solid rgba(0, 0, 0, 0.3);

	--shadow-color: #91edffaa;
	--shadow-popover: 0px 0px 16px #bcbcbc;
	--shadow: 0px 0px 2px var(--shadow-color);
	--inner-shadow: inset 0px 0px 2px var(--shadow-color);

	--padding-big: 15px;
	--padding: 10px;
	--padding-small: 5px;
	--padding-smaller: 3px;
	--margin: 5px;
	--margin-big: 10px;
	--margin-small: 3px;

	/* =======================================
    
            ANIMATIONS
    
        ======================================= */
	--transition: 0.15s all ease-in-out;
	--fast-transition: 0.075s all ease-in-out;
	--slow-transition: 0.3s all ease-in-out;
}

@media (prefers-color-scheme: dark) {
	:root {
		/* =======================================
    
            TEXT
    
        ======================================= */
		//Color
		--color: white;
		--color-header: #2e9afe;
		--color-header-fine: #2e9afe99;
		--color-header-raw: 0, 208, 214;
		--color-secondary: black;
		--color-fine: rgba(255, 255, 255, 0.6);

		//Size
		--text-bigger: 1.8em;
		--text-big: 1.5em;
		--text-normal: 1em;

		/* =======================================
    
            COLORS
    
        ======================================= */
		--success: rgb(25, 145, 14);
		--warning: rgba(255, 251, 33, 1);
		--error: rgba(255, 64, 80, 1);
		--disabled: rgba(100, 100, 100, 0.6);

		--background-color: #1c1c1c;
		--background-color-item: #424242;
		--background-color-hover: rgba(255, 255, 255, 0.15);
		--background-color-selected: rgba(255, 255, 255, 0.3);
		--background-color-disabled: rgba(0, 0, 0, 0.6);
		--background-color-highlighted: rgba(100, 100, 100, 1);
		--background-color-item-popup: #707070;

		/* =======================================
    
            STYLE
    
        ======================================= */
		--border-radius: 5px;
		--border-radius-small: 2px;
		--border-width: 1px;
		--border-color-strong: rgba(255, 255, 255, 0.6);
		--border: 1px solid rgba(255, 255, 255, 0.3);

		--shadow-popover: 0px 0px 16px black;
		--shadow-color: #6e6e6eaa;
		--shadow: 0px 0px 2px var(--shadow-color);
		--inner-shadow: inset 0px 0px 2px var(--shadow-color);

		--padding-big: 15px;
		--padding: 10px;
		--padding-small: 5px;
		--padding-smaller: 3px;
		--margin: 5px;
		--margin-big: 10px;
		--margin-small: 3px;

		/* =======================================
    
            ANIMATIONS
    
        ======================================= */
		--transition: 0.15s all ease-in-out;
		--fast-transition: 0.075s all ease-in-out;
		--slow-transition: 0.3s all ease-in-out;
	}
}
