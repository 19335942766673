.room-manager {
	position: relative;
	overflow: hidden;
	width: 300px;

	min-height: 250px;
	.room-list {
		transition: var(--transition);
		display: absolute;
		width: 300px;
		top: 0;
		margin-left: 0px;
		.home-occs-content {
			height: 300px;
		}
	}

	.room-creation {
		transition: var(--transition);
		margin-left: 300px;
		top: 0;
		width: 300px;
		position: absolute;
	}

	&.creation {
		.room-list {
			margin-left: -300px;
		}

		.room-creation {
			margin-left: 0px;
		}
	}
}

.content {
	height: 100%;
}
