.settings-item {
	width: max-content;

	.settings-title {
		display: flex;
		justify-content: center;
		padding: var(--padding);
		font-size: var(--text-big);
		margin-bottom: var(--padding-big);
		color: var(--color-header);
	}

	.settings-content {
		height: min-content;
	}

	.settings-footer {
		margin-top: var(--padding-big);
		display: flex;
		justify-content: center;
	}
}
