.profile {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	height: 100%;
	gap: 2em;
	@import "../settings/SettingsItem.scss";
}

.personal-user {
	display: flex;
	gap: var(--padding);

	.change-password-validations {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.change-password-validations-item {
			padding: var(--padding-small) 0;
		}
		.NEUTRAL {
			color: var(--color-fine);
		}

		.VALID {
			color: var(--success);
		}

		.INVALID {
			color: var(--error);
		}
	}
}
