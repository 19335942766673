.App {
	text-align: center;
}

#root,
html,
body,
.app {
	width: 100%;
	background-color: var(--background-color);
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar-thumb {
	background: rgba(128, 128, 128, 0.6);
	border-radius: 15px;
}

::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-track {
	display: none;
}

* {
	scrollbar-color: rgba(128, 128, 128, 0.6) rgba(0, 0, 0, 0);
	scrollbar-width: thin;
}
