.settings {
	display: flex;
	flex-wrap: wrap;
	gap: 2em;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	@import "./SettingsItem.scss";
}

.cookies-setting-content {
	display: flex;
	text-align: center;
	justify-content: center;
}

.cookies-setting-footer {
	display: flex;
	justify-content: center;
	margin-top: var(--padding-big);
}
